import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import {
  checkTimeRange,
  getNotificationTimeStatus,
} from "../../../../../../Utils/commonFunctions";
import LocalImages from "../../../../../../Utils/localImages";
import moment from "moment";
import {
  RfqAcceptedChip,
  RfqCancelledChip,
  RfqCompletedChip,
  RfqDraftChip,
  RfqSentChip,
} from "../../common/styledComponent";
import { useHistory } from "react-router-dom";
import Utils from "../../../../../../Utils";
import NoRequests from "../../../common/NoRequests";
import NoResult from "../../../Bookings/noResult";
import EnquireModal from "../../../Requests/maintainance/enquireModal";
// import SearchNotFound from "../../common/SearchNotFound";
const { verticalDots } = LocalImages;

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 400,
  fontSize: "14px",
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#828282",
  fontWeight: 400,
  fontSize: "12px",
});

const StyledButton = styled(Button)({
  textTransform: "initial",
  border: "1px solid #9EDBEB",
  color: "#097390",
  fontSize: ".875rem",
  fontWeight: 500,
  borderRadius: 0,
  padding: ".688rem 1.35rem",
  "&:hover": {
    border: "1px solid #9EDBEB",
    color: "#097390",
  },
});

const ViewAllDeskTop = ({ data, search }: any) => {
  const getMonth = (date: any) => {
    return `${moment(date).format("MMM")} ${moment(date).format("D")}, ${moment(
      date
    ).format("YYYY")}`;
  };

  const history = useHistory();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [job, setJob] = useState<any>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setJob(null);
    setAnchorEl(null);
  };

  const enquireHandler = (jobId: any) => (e: any) => {
    setModalOpen(true);
  };

  const routeToDetailBqmodalOpen = (jobId: any) => (e: any) => {
    history.push(`${Utils.Pathname.JOB_DETAILS}/${jobId}`, true);
  };

  const BuildQuoteHandler = (job: any) => (e: any) => {
    // if (job?.status === "SENT" || job?.status === "COMPLETED" ) {
    //   history.push({
    //     pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW,
    //     state: {
    //       id: job?._id,
    //       quoteSelection: "TEMPLATE",
    //       status: true,
    //     },
    //   });
    //   return;
    // }
    history.push(`${Utils.Pathname.JOB_DETAILS}/${job?._id}`);
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            "& thead": {
              background: "rgba(240, 240, 240, 0.2)",
              borderTop: "1px solid #e0e0e0",
              borderBottom: "1px solid #e0e0e0",
              "& th": {
                fontWeight: 400,
                fontSize: ".75rem",
                minheight: "2.25rem",
                color: "#828282",
                paddingY: ".625rem",
              },
            },

            "& tbody": {
              background: "#fff",

              "& tr": {
                paddingY: "1.5rem",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Data Recieved</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Request Type</TableCell>
              <TableCell>Request Details</TableCell>
              <TableCell>Date Needed</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length
              ? data?.map((el: any) => {
                  return (
                    <TableRow key={el?._id}>
                      <TableCell>
                        <Box>
                          <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                            {moment(new Date(el?.createdAt)).format(
                              "DD/MM/YYYY"
                            )}
                            {/* {/ {getMomentDate(el?.createdAt, "DD/MM/YYYY")} /} */}
                          </PrimaryBlackTypoGraphy>
                          <SecondaryBlackTypoGraphy>
                            Received {getNotificationTimeStatus(el?.createdAt)}{" "}
                            ago
                          </SecondaryBlackTypoGraphy>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                            {el?.job_detail?.clientDetail?.businessName.length <
                            20
                              ? el?.job_detail?.clientDetail?.businessName
                              : `${el?.job_detail?.clientDetail?.businessName?.slice(
                                  0,
                                  20
                                )}...`}
                          </PrimaryBlackTypoGraphy>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                            {el?.requestType}
                          </PrimaryBlackTypoGraphy>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <PrimaryBlackTypoGraphy className="rfqRequestType">
                          {el?.job_detail?.projectType?.length &&
                          el?.job_detail?.projectType?.length > 1 ? (
                            el?.job_detail?.projectType?.map(
                              (el: any, i: any, arr: any) => {
                                if (i === arr.length - 1) {
                                  return (
                                    <React.Fragment key={el}>
                                      {el}
                                    </React.Fragment>
                                  );
                                }
                                return (
                                  <React.Fragment key={el}>
                                    {`${el}, `}
                                  </React.Fragment>
                                );
                              }
                            )
                          ) : el?.job_detail?.projectType?.length ? (
                            <>{el?.job_detail?.projectType[0]}</>
                          ) : (
                            "-"
                          )}
                        </PrimaryBlackTypoGraphy>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {el?.requestType === "RFQ" ? (
                            "-"
                          ) : (
                            <>
                              <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                                April 15, 2022
                              </PrimaryBlackTypoGraphy>
                              <SecondaryBlackTypoGraphy>
                                10 AM - 3 PM
                              </SecondaryBlackTypoGraphy>
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          justifyContent="space-between"
                        >
                          {el?.requestType === "RFQ" &&
                          el?.status === "SENT" ? (
                            <RfqSentChip>Sent</RfqSentChip>
                          ) : el?.requestType === "RFQ" &&
                            el?.status === "DRAFT" ? (
                            <RfqDraftChip>draft</RfqDraftChip>
                          ) : el?.requestType === "RFQ" &&
                            el?.status === "BUILD_QUOTE" ? (
                            el?.isDraft ? (
                              <RfqDraftChip>Draft</RfqDraftChip>
                            ) : (
                              <StyledButton
                                sx={{
                                  width: "7.76rem",
                                  marginRight: "1rem",
                                }}
                                onClick={routeToDetailBqmodalOpen(el?._id)}
                                disableRipple
                              >
                                Build Quote
                              </StyledButton>
                            )
                          ) : el?.requestType === "RFQ" &&
                            el?.status === "COMPLETED" ? (
                            <RfqCompletedChip>Completed</RfqCompletedChip>
                          ) : el?.requestType === "RFQ" &&
                            el?.status === "CANCELLED" ? (
                            <RfqCancelledChip>Cancelled</RfqCancelledChip>
                          ) : (
                            <Typography>{el?.status}</Typography>
                          )}

                          <IconButton
                            onClick={(e: any) => {
                              setJob(el);
                              localStorage.setItem(
                                "clientEmail",
                                el?.job_detail?.clientDetail?.email
                              );
                              handleMenu(e);
                            }}
                          >
                            <img src={verticalDots} alt="actions" />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            BuildQuoteHandler(job)("");
          }}
        >
          View request
        </MenuItem>
        <MenuItem
          onClick={(e: any) => {
            enquireHandler(job)(e);
            handleClose();
          }}
        >
          Message Client
        </MenuItem>
        <MenuItem
          onClick={() => {
            BuildQuoteHandler(job)("");
          }}
        >
          View comment thread
        </MenuItem>
      </Menu>

      <EnquireModal ModalOpen={modalOpen} setModalOpen={setModalOpen} />
      {!search && !data?.length ? <NoRequests /> : null}
      {search && !data?.length ? <NoResult /> : null}
    </>
  );
};

export default ViewAllDeskTop;
